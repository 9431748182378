import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, vModelSelect as _vModelSelect, createCommentVNode as _createCommentVNode, vShow as _vShow } from "vue"

const _hoisted_1 = { class: "camera-details" }
const _hoisted_2 = { class: "video-input" }
const _hoisted_3 = { class: "select" }
const _hoisted_4 = ["value"]
const _hoisted_5 = { class: "right-section" }
const _hoisted_6 = { class: "reference-image" }
const _hoisted_7 = ["src"]
const _hoisted_8 = ["disabled"]
const _hoisted_9 = { class: "generated-video" }
const _hoisted_10 = ["src"]
const _hoisted_11 = { class: "modal-overlay" }

import { ref, computed, watch } from 'vue';
import { useStore } from 'vuex';


export default /*@__PURE__*/_defineComponent({
  __name: 'CameraDetails',
  setup(__props) {

const store = useStore();
const selectedVideo = computed(() => store.state.ViewEdit.selectedVideo);

const videoSegment = computed(() => {
  return store.getters['ViewEdit/getVideoSegmentById'](selectedVideo.value);
});

const generatedVideo = computed(() => {
  return videoSegment.value ? videoSegment.value.videoUrl : null;
});

// 计算属性绑定到 Vuex 中的 videoPrompt
const videoPrompt = computed({
  get: () => store.getters['ViewStoryboard/getVideoPromptById'](selectedVideo.value),
  set: (value) => {
    store.commit('ViewStoryboard/updateRow', {
      id: selectedVideo.value,
      updatedRow: { video_prompt: value }
    });
  }
});

// 计算属性绑定到 Vuex 中的 referenceImage
const referenceImage = computed({
  get: () => store.getters['ViewStoryboard/getImageById'](selectedVideo.value),
  set: (value) => {
    store.commit('ViewStoryboard/updateRow', {
      id: selectedVideo.value,
      updatedRow: { image: value }
    });
  }
});

const motionOptions = ref(["前推镜头", "后拉镜头", "摇镜头", "平移镜头", "跟拍镜头", "甩镜头", "上升镜头", "下降镜头", "环绕镜头", "穿越镜头"]);

// 计算属性绑定到 Vuex 中的 
const cameraMotion= computed({
  get() {
    return store.getters['ViewStoryboard/getCameraMotionById'](selectedVideo.value);
  },
  set(value) {
    store.commit('ViewStoryboard/setCameraMotion', {
      id: selectedVideo.value,
      cameraMotion: value
    });
  }
});



// isGenerating computed 属性
const isGenerating = computed({
  get() {
    return store.getters['ViewStoryboard/getIsVedioGeneratingById'](selectedVideo.value);
  },
  set(value) {
    store.commit('ViewStoryboard/setIsVideoGenerating', {
      id: selectedVideo.value,
      isVideoGenerating: value,
    });
  }
});

// 控制弹窗显示
const showModal = ref(false);

// 监听 selectedVideo 的变化
watch(selectedVideo, (newValue) => {
  // 当 selectedVideo 为空或其他条件不满足时，关闭弹窗
  if (!newValue) {
    showModal.value = false;
  }
});

function handleGenerateVideo() {
  // 提前检查 selectedVideo 和其他依赖项是否有效
  if (!selectedVideo.value) {
    console.error("Selected video is not defined");
    return;
  }

  // 检查是否正在生成，避免重复触发
  if (isGenerating.value) return;

  // 显示确认弹窗
  showModal.value = true;
}

// 确认生成视频
function confirmGenerateVideo() {
  showModal.value = false; // 关闭弹窗

  // 将 isGenerating 设置为 true，表明生成过程开始
  isGenerating.value = true;

  const video_id = videoSegment.value?.video_id;
  const first_frame_image = referenceImage.value;
  const prompt = videoPrompt.value + "   " + cameraMotion.value;

  // 检查依赖项是否定义
  if (!video_id || !first_frame_image || !prompt) {
    console.error("Missing required parameters for video generation.");
    isGenerating.value = false; // 重置生成状态
    return;
  }

  // 调用 Vuex 的 generateVideo action
  store.dispatch('ViewEdit/generateVideo', {
    videoPrompt: prompt,
    video_id: video_id,
    first_frame_image: first_frame_image,
  })
    .then(() => {
      console.log("视频生成请求完成");
    })
    .catch((error) => {
      console.error("生成视频失败:", error);
    })
    .finally(() => {
      isGenerating.value = false;
    });
}

// 取消生成视频
function cancelGenerateVideo() {
  showModal.value = false; // 关闭弹窗
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[3] || (_cache[3] = _createElementVNode("label", { for: "prompt" }, "视频提示词:", -1)),
      _withDirectives(_createElementVNode("textarea", {
        id: "prompt",
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((videoPrompt).value = $event)),
        placeholder: "输入视频提示词"
      }, null, 512), [
        [_vModelText, videoPrompt.value]
      ]),
      _createElementVNode("div", _hoisted_3, [
        _cache[2] || (_cache[2] = _createElementVNode("label", null, "运镜：", -1)),
        _withDirectives(_createElementVNode("select", {
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((cameraMotion).value = $event)),
          class: "select-box"
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(motionOptions.value, (option) => {
            return (_openBlock(), _createElementBlock("option", {
              key: option,
              value: option
            }, _toDisplayString(option), 9, _hoisted_4))
          }), 128))
        ], 512), [
          [_vModelSelect, cameraMotion.value]
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _cache[4] || (_cache[4] = _createElementVNode("label", { for: "referenceImage" }, "视频参考图:", -1)),
      _createElementVNode("div", _hoisted_6, [
        (referenceImage.value)
          ? (_openBlock(), _createElementBlock("img", {
              key: 0,
              src: referenceImage.value,
              alt: "Reference Image",
              crossorigin: "anonymous"
            }, null, 8, _hoisted_7))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("button", {
        onClick: handleGenerateVideo,
        disabled: isGenerating.value
      }, _toDisplayString(isGenerating.value ? '生成中...' : '生成视频'), 9, _hoisted_8)
    ]),
    _createElementVNode("div", _hoisted_9, [
      _createElementVNode("video", {
        src: generatedVideo.value ?? undefined,
        controls: ""
      }, null, 8, _hoisted_10)
    ]),
    _withDirectives(_createElementVNode("div", _hoisted_11, [
      _createElementVNode("div", { class: "modal" }, [
        _cache[5] || (_cache[5] = _createElementVNode("h4", null, "生成视频确认", -1)),
        _cache[6] || (_cache[6] = _createElementVNode("p", null, "每条视频的费用为¥3，会覆盖掉之前的视频", -1)),
        _cache[7] || (_cache[7] = _createElementVNode("p", null, "您确定要生成视频吗？", -1)),
        _createElementVNode("div", { class: "button-group" }, [
          _createElementVNode("button", { onClick: confirmGenerateVideo }, "确认"),
          _createElementVNode("button", { onClick: cancelGenerateVideo }, "取消")
        ])
      ])
    ], 512), [
      [_vShow, showModal.value]
    ])
  ]))
}
}

})